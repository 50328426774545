var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-page"},[_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{attrs:{"lg":1}},[_c('el-button',{staticClass:"default-button btn button-left",attrs:{"size":"mini","icon":"el-icon-back"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back ")])],1),_c('el-col',{attrs:{"lg":{ span: 6, offset: 2 },"md":8,"sm":24}},[_c('div',{staticClass:"profile-card text-center",staticStyle:{"max-width":"400px","margin-bottom":"20px"},attrs:{"shadow":"never"}},[_c('div',{staticClass:"my-3",attrs:{"align":"center"}},[(_vm.getUserprofilepicture && _vm.getUserprofilepicture.length > 20)?_c('img',{staticClass:"inline-block",staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.getUserprofilepicture,"alt":"Avatar","width":"50"}}):_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/img/avatar.svg"),"alt":"Avatar","width":"50"}})]),_c('div',{staticClass:"list-group text-left"},[_c('router-link',{class:_vm.getClass('statistics'),attrs:{"to":{
                name: 'statistics',
              }}},[_c('i',{staticClass:"el-icon-pie-chart"}),_vm._v(" Dashboard Statistics")]),_c('router-link',{class:_vm.getClass('expiration-reminders'),attrs:{"to":{
                name: 'expiration-reminders',
              }}},[_c('i',{staticClass:"el-icon-timer"}),_vm._v(" Expiration & Reminders")]),_c('router-link',{class:_vm.getClass('application-settings'),attrs:{"to":{
                name: 'application-settings',
              }}},[_c('i',{staticClass:"el-icon-setting"}),_vm._v("Application Settings")]),_c('router-link',{class:_vm.getClass('document-notifications'),attrs:{"to":{
                name: 'document-notifications',
              }}},[_c('i',{staticClass:"el-icon-bell"}),_vm._v(" Notifications")]),(_vm.isWorkspaceIsBusiness(this.getActiveWorkspace))?_c('router-link',{class:_vm.getClass('myapps-credentials'),attrs:{"to":{
                name: 'myapps-credentials',
              }}},[_c('i',{staticClass:"el-icon-link"}),_vm._v(" My Apps & Credentials")]):_vm._e(),(_vm.isWorkspaceIsBusiness(this.getActiveWorkspace))?_c('router-link',{class:_vm.getClass('global-filters'),attrs:{"to":{
                name: 'global-filters',
              }}},[_c('i',{staticClass:"el-icon-s-operation"}),_vm._v(" Global Filters")]):_vm._e(),(_vm.isWorkspaceIsBusiness(this.getActiveWorkspace))?_c('router-link',{class:_vm.getClass('app-mode-configuration'),attrs:{"to":{
                name: 'app-mode-configuration',
              }}},[_c('i',{staticClass:"el-icon-guide"}),_vm._v(" App mode")]):_vm._e()],1)])]),_c('el-col',{attrs:{"lg":14,"md":16,"sm":24}},[_c(_vm.layout,{tag:"component"},[_c('router-view',{attrs:{"layout":_vm.layout},on:{"update:layout":function($event){_vm.layout=$event}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }